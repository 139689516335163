.about-section {
	margin-left: 40px;
	margin-right: 40px;
}

.about-container {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 960px;
	width: 100%;
}

.about-wrap {
	display: flex;
	width: 740px;
	flex-direction: column;
	padding-top: 48px;
}

.about-wrap h2 {
	margin-top: 16px;
}

.about-leader {
	display: grid;
	grid-template-columns: 1.52fr 1fr;
	width: 100%;
	margin-top: 48px;
}

.about-personal-statement p {
	/*	max-width: 493.33px;*/
	margin-bottom: 20px;
	line-height: 24px;
}

.about-highlights {
	display: grid;
	gap: 0px;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	margin-top: 64px;
}

.about-img {
	position: relative;
	background-image: url("divider.png");
	background-position: 40px 50%;
	background-size: 16px 120px;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
}

.about-img img {
	position: absolute;
	right: 0px;
	max-height: 212px;
	max-width: 212px;
	border: 4px solid #fff;
	border-radius: 24px;
	box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 12%);
}

.about-highlight-grid-1 {
	border-bottom: 1px solid #222;
	padding-right: 24px;
	padding-bottom: 24px;
}

.about-highlight-grid-2 {
	border-bottom: 1px solid #222;
	border-left: 1px solid #222;
	padding-right: 24px;
	padding-bottom: 24px;
	padding-left: 24px;
}

.about-highlight-grid-3 {
	border-bottom: 1px solid #222;
	border-left: 1px solid #222;
	padding-bottom: 24px;
	padding-left: 24px;
}

.about-highlight-grid-4 {
	padding-top: 24px;
	padding-right: 24px;
}

.about-highlight-grid-5 {
	border-left: 1px solid #222;
	padding-left: 24px;
	padding-top: 24px;
	padding-right: 24px;
}

.about-highlight-grid-6 {
	border-left: 1px solid #222;
	padding-top: 24px;
	padding-left: 24px;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.about-wrap {
		padding-bottom: 96px;
		width: 100%;
	}

	.about-wrap h2 {
		text-align: left;
	}

	.about-img {
		background-position: 20px 50%;
	}
}

@media screen and (max-width: 767px) {
	.about-section {
		margin-right: 32px;
		margin-left: 32px;
	}

	.about-wrap {
		padding-bottom: 0;
	}

	.about-img img {
		max-height: 144px;
		max-width: 144px;
	}

	.about-img {
		max-height: 144px;
		background-image: none;
	}
	.about-highlights {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
	}

	.about-highlight-grid-1 {
		grid-row: span 1;
		grid-column: span 1;
		padding-right: 0;
	}

	.about-highlight-grid-2 {
		grid-row: span 1;
		grid-column: span 1;
	}

	.about-highlight-grid-3 {
		grid-row: span 1;
		grid-column: span 2;
		border-left: none;
		padding-left: 0;
		padding-top: 24px;
	}

	.about-highlight-grid-4 {
		grid-row: span 1;
		grid-column: span 2;
		border-bottom: 1px solid #222;
		padding-bottom: 24px;
	}

	.about-highlight-grid-5 {
		grid-row: span 1;
		grid-column: span 2;
		border-bottom: 1px solid #222;
		border-left: none;
		padding-bottom: 24px;
		padding-left: 0;
	}

	.about-highlight-grid-6 {
		grid-row: span 1;
		grid-column: span 2;
		border-left: none;
		padding-bottom: 24px;
		padding-left: 0;
	}
}

@media screen and (max-width: 479px) {
	.about-section {
		margin-right: 32px;
		margin-left: 32px;
	}

	.about-img {
		position: absolute;
		width: 85%;
		height: 36vw;
		margin-top: -43vw;
		background-image: url("dividerHorizontal.png");
		background-position: 0% 100%;
		background-size: 96px 16px;
	}

	.about-img img {
		width: 28vw;
		height: 28vw;
		border-radius: 100px;
		box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 18%);
	}

	.about-wrap {
		margin-left: auto;
		margin-right: auto;
	}

	.about-wrap h2 {
		width: 75%;
		margin-top: 0px;
		margin-bottom: 16px;
		font-size: 40px;
		line-height: 48px;
		text-align: left;
	}

	.about-leader {
		grid-template-columns: 2fr;
	}
}
