.landing-section {
	display: flex;
	margin-left: 40px;
	margin-right: 40px;
	margin-top: 140px;
	text-align: left;
}

.landing-container {
	width: 100%;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.landing-heading {
	margin-bottom: 50px;
}

.landing-paragraph p {
	font-size: 1.2em;
	margin-bottom: 20px;
	padding: 0;
	max-width: 740px;
}

.landing-divider {
	width: 100%;
	height: 1px;
	margin-top: 96px;
	background-color: #222;
}

.anim {
	transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	transform-style: preserve-3d;
	opacity: 1;
}

@media screen and (max-width: 767px) {
	.landing-section {
		margin-top: 64px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.landing-container {
		text-align: left;
	}
	.landing-wrap {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.landing-heading h1 {
		font-size: 3em;
		line-height: 56px;
	}
	.landing-paragraph p {
		font-size: 1em;
		line-height: 28px;
	}
}

@media screen and (max-width: 479px) {
	.landing-section {
		margin-top: 0px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.landing-wrap {
		margin-top: 48px;
		margin-bottom: 64px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.landing-heading {
		margin-bottom: 16px;
		font-size: 40px;
		line-height: 48px;
	}
	.landing-heading h1 {
		font-size: 40px;
		line-height: 48px;
	}
	.landing-paragraph p {
		margin-top: 16px;
		margin-bottom: 0px;
		line-height: 24px;
		letter-spacing: -0.015em;
	}
	.landing-divider {
		margin-top: 64px;
	}
}
