body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: #222;
  background-image: url("background.png"),
    linear-gradient(180deg, #f8f8f8, #f8f8f8);
  background-color: #f8f8f8;
  background-origin: border-box;
  background-position: -210px 0, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 140%, auto;
}

@media screen and (max-width: 479px) {
  body {
    background-image: url("background.png"),
      -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
    background-image: url("background.png"),
      linear-gradient(180deg, transparent, transparent);
    background-position: -100px -20px, 0 0;
    background-size: 210%, auto;
  }
}

@media screen and (max-width: 767px) {
  body {
    background-image: url("background.png"),
      -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
    background-image: url("background.png"),
      linear-gradient(180deg, transparent, transparent);
    background-position: -80px 0px, 0px 0px;
  }
}

::selection {
  background-color: #222;
  color: #f8f8f8;
}

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
