body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: #222;
  background-image: url(/static/media/background.825c21ff.png),
    linear-gradient(180deg, #f8f8f8, #f8f8f8);
  background-color: #f8f8f8;
  background-origin: border-box;
  background-position: -210px 0, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 140%, auto;
}

@media screen and (max-width: 479px) {
  body {
    background-image: url(/static/media/background.825c21ff.png),
      linear-gradient(180deg, transparent, transparent);
    background-position: -100px -20px, 0 0;
    background-size: 210%, auto;
  }
}

@media screen and (max-width: 767px) {
  body {
    background-image: url(/static/media/background.825c21ff.png),
      linear-gradient(180deg, transparent, transparent);
    background-position: -80px 0px, 0px 0px;
  }
}

::selection {
  background-color: #222;
  color: #f8f8f8;
}

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

.animatedwrapper {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: -100px;
	z-index: -1;
	width: 100%;
	height: 2000px;
	isolation: isolate;
	/* 
            Add fallback background here
            background: url('');
            */
}

@media only screen and (max-width: 600px) {
	.animatedwrapper {
		top: -500px;
	}
	.dot {
		--size: 100px;
	}
}

.dot {
	--size: 700px;
	/* Only show the animated dots for browsers that support the required features */
	display: none;
	position: absolute;
	left: calc(var(--size) / 3 * -1);
	top: calc(var(--size) / 3 * -1);
	width: var(--size);
	height: var(--size);
	mix-blend-mode: soft-light;
	border-radius: var(--size);
	-webkit-filter: blur(calc(var(--size) / 6));
	        filter: blur(calc(var(--size) / 6));
	-webkit-animation: dot-animation ease-in-out 15s infinite;
	        animation: dot-animation ease-in-out 15s infinite;
	will-change: transform;
}
@supports (mix-blend-mode: soft-light) and ((-webkit-filter: blur(1px)) or (filter: blur(1px))) {
	.animatedwrapper {
		background: none;
	}
	.dot {
		display: block;
	}
}

@media screen and (max-width: 479px) {
	.dot {
		mix-blend-mode: color;
	}
}
.dot--one {
	background: #ed2a24;
}
.dot--two {
	background: #f48937;
	-webkit-animation-delay: -5s;
	        animation-delay: -5s;
}
.dot--three {
	background: #bd3690;
	-webkit-animation-delay: -10s;
	        animation-delay: -10s;
}
@-webkit-keyframes dot-animation {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	33% {
		-webkit-transform: translate(calc(var(--size) / 1.5), 0);
		        transform: translate(calc(var(--size) / 1.5), 0);
	}
	66% {
		-webkit-transform: translate(0, calc(var(--size) / 1.5));
		        transform: translate(0, calc(var(--size) / 1.5));
	}
	100% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}
@keyframes dot-animation {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	33% {
		-webkit-transform: translate(calc(var(--size) / 1.5), 0);
		        transform: translate(calc(var(--size) / 1.5), 0);
	}
	66% {
		-webkit-transform: translate(0, calc(var(--size) / 1.5));
		        transform: translate(0, calc(var(--size) / 1.5));
	}
	100% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}

.Navigation_navbar__NV1te {
	display: flex;
	justify-content: space-between;
	padding-left: 42px;
}

.Navigation_navlinks__3YCHB {
	display: flex;
}

.Navigation_navbar__NV1te p {
	color: #222;
	font-family: "PitchSansSemibold";
	cursor: pointer;
	padding: 12px 18px 18px;
	text-decoration: none;
    -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    opacity: 1;
}

.Navigation_navbar__NV1te .Navigation_hoverLink__2XPwd:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAMCAYAAAB4MH11AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBtVILEYJAEL07ChjhaKANNIE2UBNABG1gBGxgAzGBNAAbUADwPWaPYRgEkfHNLLv32/d2WaX+DD10aK1dGGP2WutdVVWWW3KUYy+Bj4qieGTAJAJJHCAMYQs1jktZlmfw5KMESG6h7g6zXEN5DLsijJ1SXFnSsTLYXp5mINl0q9Hd5FD+pGokTWBH3E/UADqCcpCs2iS67yIVw8K+kj/B9/1IqsmEpH5rGiatT6IiS9P0MCU5wTesmlrRhdDtm5b6upfso/oRbKmEAQelIQDW4qOhkRsD/xeHAiGn8NAQeJ7n1N/UTMjE0W8bAiyWcv5S8xHz48bctaju19hIfoNWiy0/b+AHke9thCIQAAAAAElFTkSuQmCC);
  background-position: 50% 100%;
  background-size: 12px 6px;
  background-repeat: no-repeat;
}

.Navigation_logoImage__2lLcw {
	margin-top: 20px;
	position: fixed;
	height: 100%;
	width: 60px;
	overflow: hidden;
}

@media screen and (max-width: 1262px) {
	.Navigation_logoImage__2lLcw {
		position: static;
	}
}

@media screen and (max-width: 479px) {
	.Navigation_logoImage__2lLcw {
		width: 36px;
	}
}

.landing-section {
	display: flex;
	margin-left: 40px;
	margin-right: 40px;
	margin-top: 140px;
	text-align: left;
}

.landing-container {
	width: 100%;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.landing-heading {
	margin-bottom: 50px;
}

.landing-paragraph p {
	font-size: 1.2em;
	margin-bottom: 20px;
	padding: 0;
	max-width: 740px;
}

.landing-divider {
	width: 100%;
	height: 1px;
	margin-top: 96px;
	background-color: #222;
}

.anim {
	-webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
		rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d;
	opacity: 1;
}

@media screen and (max-width: 767px) {
	.landing-section {
		margin-top: 64px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.landing-container {
		text-align: left;
	}
	.landing-wrap {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.landing-heading h1 {
		font-size: 3em;
		line-height: 56px;
	}
	.landing-paragraph p {
		font-size: 1em;
		line-height: 28px;
	}
}

@media screen and (max-width: 479px) {
	.landing-section {
		margin-top: 0px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.landing-wrap {
		margin-top: 48px;
		margin-bottom: 64px;
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.landing-heading {
		margin-bottom: 16px;
		font-size: 40px;
		line-height: 48px;
	}
	.landing-heading h1 {
		font-size: 40px;
		line-height: 48px;
	}
	.landing-paragraph p {
		margin-top: 16px;
		margin-bottom: 0px;
		line-height: 24px;
		letter-spacing: -0.015em;
	}
	.landing-divider {
		margin-top: 64px;
	}
}

.footer-container {
	display: flex;
	justify-content: flex-end;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
	align-items: center;
	padding: 0 0 96px;
	background-image: url(/static/media/footer.993d2a7c.png);
	background-position: 50% 100%;
	background-size: 1724px;
	background-repeat: no-repeat;
}

.footer-wrap {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: right;
	width: 100%;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.footer-links {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.footer-links a {
	text-decoration: none;
	margin-left: 20px;
	line-height: 18px;
	letter-spacing: 0.03em;
	font-size: 0.9em;
}

.footer-title {
	margin-bottom: 24px;
}

.footer-top-divider {
	display: flex;
	margin-top: 96px;
	padding-bottom: 240px;
	flex-wrap: nowrap;
	align-items: flex-start;
}

.footer-divider {
	width: 100%;
	height: 1px;
	align-self: center;
	background-color: #222;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.footer-container {
		padding-right: 30px;
		padding-left: 30px;
	}
	
	.footer-top-divider {
		margin-top: 64px;
		padding-bottom: 96px;
	}
}

@media screen and (max-width: 767px) {
	.footer-container {
		overflow: hidden;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		padding-right: 32px;
		padding-bottom: 48px;
		padding-left: 32px;
		flex-direction: column;
		text-align: center;
	}

	.footer-top-divider {
		margin-top: 64px;
		padding-bottom: 104px;
	}
}

@media screen and (max-width: 479px) {
	.footer-container {
		overflow: hidden;
		width: auto;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		padding-right: 32px;
		padding-bottom: 48px;
		padding-left: 32px;
		flex-direction: column;
		background-position: 50% 100%;
		background-size: 900px 400px;
	}

	.footer-top-divider {
		padding-bottom: 96px;
	}

	.footer-wrap {
		text-align: left;
	}

	.footer-links {
		justify-content: flex-start;
		font-size: 0.9em;
		line-height: 18px;
	}

	.footer-links a {
		margin-left: 0;
		margin-right: 20px;
	}

	.footer-title {
		font-size: 2.5em;
		line-height: 48px;
	}
}


.projects-section {
	display: flex;
	justify-content: center;
	margin-top: 96px;
	margin-left: 40px;
	margin-right: 40px;
}

.projects-container {
	display: flex;
	flex-direction: column;
	grid-gap: 96px;
	gap: 96px;
	max-width: 960px;
	width: 100%;
}

.project-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-bottom: 96px;
}

.project-leader {
	display: flex;
	border-bottom: 1px solid #222;
	justify-content: space-between;
}

.project-title {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 24px;
	padding-right: 24px;
}

.project-image {
	max-width: 400px;
	max-height: 230px;
}

.project-image img {
	box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 12%);
	outline: 1px solid #222;
	width: 100%;
	height: auto;
}

.project-info {
	padding-top: 24px;
}

.source-links {
	padding-top: 24px;
	list-style: none;
	display: flex;
}

.source-links li {
	padding-right: 12px;
	font-family: Pitchsans;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.project-image {
		max-width: 308px;
	}
}
@media screen and (max-width: 767px) {
	.projects-section {
		margin-right: 32px;
		margin-left: 32px;
	}
	.project-title h2 {
		font-size: 2em;
		line-height: 40px;
	}
	.project-image {
		max-width: 200px;
	}
}

@media screen and (max-width: 479px) {
	.projects-section {
		margin-top: 64px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.project-title h2 {
		font-size: 2em;
		line-height: 40px;
	}

	.project-wrap {
		padding-bottom: 0px;
	}
	.project-leader {
		flex-direction: column-reverse;
	}
	.project-image {
		max-width: 180px;
		max-height: 95px;
		margin-bottom: 30px;
	}
	.project-info p {
		font-size: 1rem;
		line-height: 24px;
		letter-spacing: -0.015em;
	}
}

.about-section {
	margin-left: 40px;
	margin-right: 40px;
}

.about-container {
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 960px;
	width: 100%;
}

.about-wrap {
	display: flex;
	width: 740px;
	flex-direction: column;
	padding-top: 48px;
}

.about-wrap h2 {
	margin-top: 16px;
}

.about-leader {
	display: grid;
	grid-template-columns: 1.52fr 1fr;
	width: 100%;
	margin-top: 48px;
}

.about-personal-statement p {
	/*	max-width: 493.33px;*/
	margin-bottom: 20px;
	line-height: 24px;
}

.about-highlights {
	display: grid;
	grid-gap: 0px;
	gap: 0px;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	margin-top: 64px;
}

.about-img {
	position: relative;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAADwCAYAAABhRGbbAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALKSURBVHgB7dgxjtNQEMbxSaBPcoOQCtIQqCi9JwBxgQAXIbkI7J6A7SjJlnTLBWJzglgpIqVJ+MbrrGg9YyEh/T/JiV2sMm/8/Px+axbIbDY7+2E9ZGix1P4xVSyZUAHn8/m+PX1pyYQKGAwGd80fD4dXlkyogNPpdNueLrO3IVRAVVX3ug1exFjd+GqJPLFgxuPxT/34Bx3PJ5OJ7Xa7OwtkYImo+4XmwQ8/1225Umc21jHhDnjquq4mPnyzN16Prm+sY6LrwGM08pXXoltReEesY9IFqO2+KDUj1+1YWMekC/CoC83CpC50Xph6KUAjH1swvRSgTP1Da8Nv65heCtAPv/Vv3YqNdcxTS0av5c/20IEqsg6kOqDHzmf9ys81+o8WSLgAfwlp1n9rL9eR0acK0I+vdEx1Wm2325UFEyqgHf3Sz/0dYImECtBz/649vfaZZ4lEd0SXx+7WkonuCS9r/i9LJjoJm6U32/5MAb0FF+ACXIALcAEuwAW4ABfgAlyAC3ABLsAFuAAX4AJcgAtwAS7ABbgAF+ACXIALcAEuwAW4ABfgAlyAC3ABLsAFuAAX4AJcgAtwAS7ABbgAF6RdUBSFZZJ2gebhM0sk7AJ9+XvAXfBlsei8E3tMeFc8Go3cBUsdL47H40DXG+2KrWvCj6GvAboV79tL3xMUFkgfLvBFqXGBtHTTtQt9uGBtrQss0AVcgAvSBfgilHFBqoD5fG77/f6yOS3/qQv8JXQ4HF7ZwyLko/9kgWQWIt8Zf9fhE3BdluW1BRLqgI/+LxeUGRdEOzDVo9f8d0ytf11H3kJtcAEuSC1E2Q1ppoBm1qsBU0sGF+ACXIALcAEuwAW4ABfgAlyAC3ABLsAFuAAX4AJcgAtwAS7ABbgAF+ACXIALcAEuwAW4ABfgAlyAC3ABLsAFuAAX4AJcgAtwAS74b10w8jr6cMEfNSpmt5/MoDoAAAAASUVORK5CYII=);
	background-position: 40px 50%;
	background-size: 16px 120px;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
}

.about-img img {
	position: absolute;
	right: 0px;
	max-height: 212px;
	max-width: 212px;
	border: 4px solid #fff;
	border-radius: 24px;
	box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 12%);
}

.about-highlight-grid-1 {
	border-bottom: 1px solid #222;
	padding-right: 24px;
	padding-bottom: 24px;
}

.about-highlight-grid-2 {
	border-bottom: 1px solid #222;
	border-left: 1px solid #222;
	padding-right: 24px;
	padding-bottom: 24px;
	padding-left: 24px;
}

.about-highlight-grid-3 {
	border-bottom: 1px solid #222;
	border-left: 1px solid #222;
	padding-bottom: 24px;
	padding-left: 24px;
}

.about-highlight-grid-4 {
	padding-top: 24px;
	padding-right: 24px;
}

.about-highlight-grid-5 {
	border-left: 1px solid #222;
	padding-left: 24px;
	padding-top: 24px;
	padding-right: 24px;
}

.about-highlight-grid-6 {
	border-left: 1px solid #222;
	padding-top: 24px;
	padding-left: 24px;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.about-wrap {
		padding-bottom: 96px;
		width: 100%;
	}

	.about-wrap h2 {
		text-align: left;
	}

	.about-img {
		background-position: 20px 50%;
	}
}

@media screen and (max-width: 767px) {
	.about-section {
		margin-right: 32px;
		margin-left: 32px;
	}

	.about-wrap {
		padding-bottom: 0;
	}

	.about-img img {
		max-height: 144px;
		max-width: 144px;
	}

	.about-img {
		max-height: 144px;
		background-image: none;
	}
	.about-highlights {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
	}

	.about-highlight-grid-1 {
		grid-row: span 1;
		grid-column: span 1;
		padding-right: 0;
	}

	.about-highlight-grid-2 {
		grid-row: span 1;
		grid-column: span 1;
	}

	.about-highlight-grid-3 {
		grid-row: span 1;
		grid-column: span 2;
		border-left: none;
		padding-left: 0;
		padding-top: 24px;
	}

	.about-highlight-grid-4 {
		grid-row: span 1;
		grid-column: span 2;
		border-bottom: 1px solid #222;
		padding-bottom: 24px;
	}

	.about-highlight-grid-5 {
		grid-row: span 1;
		grid-column: span 2;
		border-bottom: 1px solid #222;
		border-left: none;
		padding-bottom: 24px;
		padding-left: 0;
	}

	.about-highlight-grid-6 {
		grid-row: span 1;
		grid-column: span 2;
		border-left: none;
		padding-bottom: 24px;
		padding-left: 0;
	}
}

@media screen and (max-width: 479px) {
	.about-section {
		margin-right: 32px;
		margin-left: 32px;
	}

	.about-img {
		position: absolute;
		width: 85%;
		height: 36vw;
		margin-top: -43vw;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAAAgCAYAAABEmHeFAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGtSURBVHgB7dcxTsJQAIfxVziAsDkCYWKx3qB6A70A6g30AtQbuLIIJxC4AdyAA5BYEiaWNgxsUP/PtAnBlEIjg+b7JS8F2n4dXl8bjAEAAAAAAAAAAAAAAADwHzjHHNRsNr3tdnvlOI5rv8dxPC+VSuPZbDY2v4B+Ns/zzGKxoH+m/sEFYCdWsY6Gl3FIoAs/BkEwNgXQz5ZObE7/U/0n+sX75awdjUbDhvv6WNOINLqKdfXbSCttru2ltjWNh2q1asIwnJgT0M/muq5ZLpe+Gr2cfl2jTb94/8cboCblcrm3s6peFX7TKor2j9VN4GvTSb7ap92NjgvMAfSz+/appt119d/z+vYmWK1W/k7fPu1u6Z/W/14AyaS6SbStUdGIdMJd3utJp7paZQP7tEt+6uvzaLPZTNOL0S/UD9W/P9RvtVpmvV5fq/eR1U9uGvoZfUdPqXj/RF1oqPGc9zRM2QlU1Levm2OOp59roMl9Oaaf3iD0C/XjdAFEmtCpDpgoPNQJU1OAnWh7TXuh5B95JdlF/8z9dKLpn9S/MAAAAAAAAAAAAAAAAAAA/HVfSKkODqQj1lAAAAAASUVORK5CYII=);
		background-position: 0% 100%;
		background-size: 96px 16px;
	}

	.about-img img {
		width: 28vw;
		height: 28vw;
		border-radius: 100px;
		box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 18%);
	}

	.about-wrap {
		margin-left: auto;
		margin-right: auto;
	}

	.about-wrap h2 {
		width: 75%;
		margin-top: 0px;
		margin-bottom: 16px;
		font-size: 40px;
		line-height: 48px;
		text-align: left;
	}

	.about-leader {
		grid-template-columns: 2fr;
	}
}

@font-face {
	font-family: "SuperiorTitle";
	src: local("SuperiorTitle"),
		url(/static/media/Superior-Medium.c0930a88.otf) format("opentype");
}

@font-face {
	font-family: "PitchSans";
	src: local("PitchSans"),
		url(/static/media/PitchSans-Medium.8b7547a6.otf) format("opentype");
}

@font-face {
	font-family: "PitchSansSemibold";
	src: local("PitchSansSemibold"),
		url(/static/media/PitchSans-SemiBold.20ae7455.otf) format("opentype");
}

* {
	-webkit-font-smoothing: antialiased;
}

.App {
	padding-top: 24px;
}

h1 {
	font-family: "SuperiorTitle";
	font-size: 4.4em;
	line-height: 80px;
	margin: 0;
}

h2 {
	font-family: "SuperiorTitle";
	font-size: 3em;
	line-height: 56px;
	margin: 0;
}

h6 {
	font-family: "PitchSans";
	font-size: 1em;
	font-weight: 600;
	text-decoration: underline;
	margin-bottom: 8px;
	line-height: 24px;
	letter-spacing: -0.015em;
}

p {
	font-family: "PitchSans";
	font-size: 1em;
	text-decoration: none;
}

a {
	text-decoration: none;
	font-family: "PitchSans";
	font-size: 1.1em;
	color: #222;
}

