.navbar {
	display: flex;
	justify-content: space-between;
	padding-left: 42px;
}

.navlinks {
	display: flex;
}

.navbar p {
	color: #222;
	font-family: "PitchSansSemibold";
	cursor: pointer;
	padding: 12px 18px 18px;
	text-decoration: none;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
}

.navbar .hoverLink:hover {
  background-image: url("./hover-image.png");
  background-position: 50% 100%;
  background-size: 12px 6px;
  background-repeat: no-repeat;
}

.logoImage {
	margin-top: 20px;
	position: fixed;
	height: 100%;
	width: 60px;
	overflow: hidden;
}

@media screen and (max-width: 1262px) {
	.logoImage {
		position: static;
	}
}

@media screen and (max-width: 479px) {
	.logoImage {
		width: 36px;
	}
}
