.footer-container {
	display: flex;
	justify-content: flex-end;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
	align-items: center;
	padding: 0 0 96px;
	background-image: url("footer.png");
	background-position: 50% 100%;
	background-size: 1724px;
	background-repeat: no-repeat;
}

.footer-wrap {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-align: right;
	width: 100%;
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.footer-links {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.footer-links a {
	text-decoration: none;
	margin-left: 20px;
	line-height: 18px;
	letter-spacing: 0.03em;
	font-size: 0.9em;
}

.footer-title {
	margin-bottom: 24px;
}

.footer-top-divider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 96px;
	padding-bottom: 240px;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-divider {
	width: 100%;
	height: 1px;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	background-color: #222;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.footer-container {
		padding-right: 30px;
		padding-left: 30px;
	}
	
	.footer-top-divider {
		margin-top: 64px;
		padding-bottom: 96px;
	}
}

@media screen and (max-width: 767px) {
	.footer-container {
		overflow: hidden;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		padding-right: 32px;
		padding-bottom: 48px;
		padding-left: 32px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.footer-top-divider {
		margin-top: 64px;
		padding-bottom: 104px;
	}
}

@media screen and (max-width: 479px) {
	.footer-container {
		overflow: hidden;
		width: auto;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
		padding-right: 32px;
		padding-bottom: 48px;
		padding-left: 32px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		background-position: 50% 100%;
		background-size: 900px 400px;
	}

	.footer-top-divider {
		padding-bottom: 96px;
	}

	.footer-wrap {
		text-align: left;
	}

	.footer-links {
		justify-content: flex-start;
		font-size: 0.9em;
		line-height: 18px;
	}

	.footer-links a {
		margin-left: 0;
		margin-right: 20px;
	}

	.footer-title {
		font-size: 2.5em;
		line-height: 48px;
	}
}

