.animatedwrapper {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: -100px;
	z-index: -1;
	width: 100%;
	height: 2000px;
	isolation: isolate;
	/* 
            Add fallback background here
            background: url('');
            */
}

@media only screen and (max-width: 600px) {
	.animatedwrapper {
		top: -500px;
	}
	.dot {
		--size: 100px;
	}
}

.dot {
	--size: 700px;
	/* Only show the animated dots for browsers that support the required features */
	display: none;
	position: absolute;
	left: calc(var(--size) / 3 * -1);
	top: calc(var(--size) / 3 * -1);
	width: var(--size);
	height: var(--size);
	mix-blend-mode: soft-light;
	border-radius: var(--size);
	filter: blur(calc(var(--size) / 6));
	animation: dot-animation ease-in-out 15s infinite;
	will-change: transform;
}
@supports (mix-blend-mode: soft-light) and (filter: blur(1px)) {
	.animatedwrapper {
		background: none;
	}
	.dot {
		display: block;
	}
}

@media screen and (max-width: 479px) {
	.dot {
		mix-blend-mode: color;
	}
}
.dot--one {
	background: #ed2a24;
}
.dot--two {
	background: #f48937;
	animation-delay: -5s;
}
.dot--three {
	background: #bd3690;
	animation-delay: -10s;
}
@keyframes dot-animation {
	0% {
		transform: translate(0, 0);
	}
	33% {
		transform: translate(calc(var(--size) / 1.5), 0);
	}
	66% {
		transform: translate(0, calc(var(--size) / 1.5));
	}
	100% {
		transform: translate(0, 0);
	}
}
