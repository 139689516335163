.projects-section {
	display: flex;
	justify-content: center;
	margin-top: 96px;
	margin-left: 40px;
	margin-right: 40px;
}

.projects-container {
	display: flex;
	flex-direction: column;
	gap: 96px;
	max-width: 960px;
	width: 100%;
}

.project-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-bottom: 96px;
}

.project-leader {
	display: flex;
	border-bottom: 1px solid #222;
	justify-content: space-between;
}

.project-title {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 24px;
	padding-right: 24px;
}

.project-image {
	max-width: 400px;
	max-height: 230px;
}

.project-image img {
	box-shadow: 11px 11px 48px 0 rgb(0 0 0 / 12%);
	outline: 1px solid #222;
	width: 100%;
	height: auto;
}

.project-info {
	padding-top: 24px;
}

.source-links {
	padding-top: 24px;
	list-style: none;
	display: flex;
}

.source-links li {
	padding-right: 12px;
	font-family: Pitchsans;
}

/*Media Screens*/

@media screen and (max-width: 991px) {
	.project-image {
		max-width: 308px;
	}
}
@media screen and (max-width: 767px) {
	.projects-section {
		margin-right: 32px;
		margin-left: 32px;
	}
	.project-title h2 {
		font-size: 2em;
		line-height: 40px;
	}
	.project-image {
		max-width: 200px;
	}
}

@media screen and (max-width: 479px) {
	.projects-section {
		margin-top: 64px;
		margin-right: 32px;
		margin-left: 32px;
	}
	.project-title h2 {
		font-size: 2em;
		line-height: 40px;
	}

	.project-wrap {
		padding-bottom: 0px;
	}
	.project-leader {
		flex-direction: column-reverse;
	}
	.project-image {
		max-width: 180px;
		max-height: 95px;
		margin-bottom: 30px;
	}
	.project-info p {
		font-size: 1rem;
		line-height: 24px;
		letter-spacing: -0.015em;
	}
}
