@font-face {
	font-family: "SuperiorTitle";
	src: local("SuperiorTitle"),
		url("./fonts/Superior-Medium.otf") format("opentype");
}

@font-face {
	font-family: "PitchSans";
	src: local("PitchSans"),
		url("./fonts/PitchSans-Medium.otf") format("opentype");
}

@font-face {
	font-family: "PitchSansSemibold";
	src: local("PitchSansSemibold"),
		url("./fonts/PitchSans-SemiBold.otf") format("opentype");
}

* {
	-webkit-font-smoothing: antialiased;
}

.App {
	padding-top: 24px;
}

h1 {
	font-family: "SuperiorTitle";
	font-size: 4.4em;
	line-height: 80px;
	margin: 0;
}

h2 {
	font-family: "SuperiorTitle";
	font-size: 3em;
	line-height: 56px;
	margin: 0;
}

h6 {
	font-family: "PitchSans";
	font-size: 1em;
	font-weight: 600;
	text-decoration: underline;
	margin-bottom: 8px;
	line-height: 24px;
	letter-spacing: -0.015em;
}

p {
	font-family: "PitchSans";
	font-size: 1em;
	text-decoration: none;
}

a {
	text-decoration: none;
	font-family: "PitchSans";
	font-size: 1.1em;
	color: #222;
}
